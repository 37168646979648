// Gatsby supports TypeScript natively!
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PricingComplete from "../components/pricing_complete";
import PricingInteriorExterior from "../components/pricing_interior_exterior";

import { StaticImage } from "gatsby-plugin-image";

/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";

const vehicle_types = [
  { name: "Cars, Trucks, SUVs, Vans" },
  { name: "Recreational Vehicles, Campers" },
  { name: "Motorcycles" },
  { name: "Watercrafts" },
  { name: "Aircrafts" },
  { name: "Fleet, Commercial, Construction" },
  { name: "Speciality Vehicles, Police, Fire, EMS" },
];

const why_steam = [
  {
    question: "Penetrates Dirt and Grime Effortlessly",
    answer: "Our Steam-Cleaning technology penetrates the surface to give you a full steam cleaning that a typical car wash never could.",
  },
  {
    question: "Protects You From Harmful Bacteria",
    answer: "We destroy mold, bacteria, dust, spores and mites—leaving your interior completely sanitized.",
  },
  {
    question: "Completely Restores Your Exterior",
    answer: "Has your auto got scratched up over time? Happens to all of us. But what you may not know is if you don’t regularly get your auto professionally detailed, the scratches can compound and even get worse. Fortunately, our team of steam specialists can have your auto’s exterior looking like it just came back from the factory (with our full service cleaning which includes waxing).",
  },
  {
    question: "Decide Whether Or Not You Want Us To Use Chemicals",
    answer: "Other car washing methods use toxic chemicals to get rid of dirt and grime. Not only is this damaging to your auto’s exterior, but it may also be putting the health of you and your loved ones at risk. With Steam Seattle, you can rest easy because we use the safest of chemicals and even give you the choice of deciding whether you want us to use chemicals or not (this is the beauty of detailing your auto using our eco-steam technology).",
  },
  {
    question: "No Scratches From Brushes",
    answer: "The brushes from your typical car wash can scratch your paint job and even damage your mirrors and antennas. Not our full service steam-cleaning though (which includes waxing)! No brushes required means your car is guaranteed to get its shiny new look back—without ever getting a single scratch. – not true",
  },
  {
    question: "We Come To You, So You Never Have To Take Another Afternoon Off To Get Your Auto Detailed Ever Again",
    answer: "Because you’re busy enough. And have enough things on your mind. Getting your auto detailed shouldn’t be one of them. So, we come to you and take care of it for you (it doesn’t get any more personalized than that—now does it?)",
  },
  // More questions...
]

export default function Example() {
  return (
    <Layout>
      <Seo title="Auto Detailing" description="Local Mobile Auto Detailing Service In Seattle. We Come To Your Location. Superior Auto Detailing For Cars, Trucks, SUVs, And Vans. Get A Free Quote!" />

      <div className="relative bg-white pt-16 overflow-hidden">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">

              <div className="mt-6">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Ready to Receive a Full Service Cleaning (Wax Included)?
                </h1>
                <p className="mt-4 text-lg text-gray-500">
                It’s what we do—better than anyone else in Seattle.
                <br/><br/>
                With our unique one-step steam cleaning process, we can efficiently clean, disinfect and shine even the dirtiest of vehicles.
                <br/><br/>
                Best of all? We use a special waterless wash process that not only gives your vehicle its shiny new look back, but it’s also twice as strong as your regular car wash—so you won’t need another cleaning for a while.
                </p>
              </div>

          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <StaticImage
                src="../images/auto-detailing.jpg"
                alt="boat detailing"
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div class="object-center">
            <StaticImage
              src="../images/car-door-taped.jpg"
              alt="car door prepped"
              class="rounded-lg object-center"
              loading="eager"
              width="450"
            />
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
              {vehicle_types.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <CheckIcon
                      className="absolute h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-8 px-4 divide-y-2 divide-gray-200 sm:px-6 lg:px-8 pb-16">
        <h2 className="text-3xl font-extrabold text-gray-900">Why Detail Your Auto the Steam Seattle Way?</h2>
        <div className="mt-6 pt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
            {why_steam.map((why) => (
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">{why.question}</dt>
                <dd className="mt-2 text-base text-gray-500">{why.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="bg-green-500">

              <PricingComplete/>

      </div>

              <PricingInteriorExterior/>

    </Layout>
  );
}
